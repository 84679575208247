<template>
    <div class="date">
        <div class="date-item" v-if="type === 'datetime' || type === 'date'">
            <div class="date-input" style="width: 80px;">
                <select @change="dateChange($event, 'year')">
                    <option :selected="item == current_date.year" :value="item" v-for="(item, index) in date.years"
                        :key="index">
                        {{ item }}
                    </option>
                </select>
            </div>
            <div class="date-hr">年</div>
            <div class="date-input">
                <select @change="dateChange($event, 'month')">
                    <option :selected="item == current_date.month" :value="item" v-for="(item, index) in date.months"
                        :key="index">
                        {{ item }}
                    </option>
                </select>
            </div>
            <div class="date-hr">月</div>
            <div class="date-input">
                <select @change="dateChange($event, 'day')">
                    <option :selected="item == current_date.day" :value="item" v-for="(item, index) in date.days"
                        :key="index">
                        {{ item }}
                    </option>
                </select>
            </div>
            <div class="date-hr">日</div>
        </div>
        <div class="date-item" v-if="type === 'datetime' || type === 'time'">
            <div class="date-input">
                <select @change="dateChange($event, 'hour')">
                    <option :selected="item == current_date.hour" :value="item" v-for="(item, index) in date.hours"
                        :key="index">
                        {{ item | padStart }}
                    </option>
                </select>
            </div>
            <div class="date-hr">:</div>
            <div class="date-input">
                <select @change="dateChange($event, 'minute')">
                    <option :selected="item == current_date.minute" :value="item" v-for="(item, index) in date.minutes"
                        :key="index">
                        {{ item | padStart }}
                    </option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
const date = {
    // 创建年月日
    years: [...Array(100).keys()].map((item) => {
        return item + 1990;
    }),
    months: [...Array(12).keys()].map((item) => {
        return item + 1;
    }),
    days: [...Array(31).keys()].map((item) => {
        return item + 1;
    }),
    hours: [...Array(24).keys()].map((item) => {
        return item;
    }),
    minutes: [...Array(60).keys()].map((item) => {
        return item;
    }),
};

export default {
    props: {
        type: {
            type: String,
            default: 'datetime'
        },
        value: {
            type: [String, Date],
            default: () => new Date()
        }
    },
    data() {
        return {
            date: {
                years: [],
                months: [],
                days: [],
                hours: [],
                minutes: [],
            },
            current_date: {
                year: 2022,
                month: 1,
                day: 1,
                hour: 0,
                minute: 0,
            },
        }
    },
    created() {
        this.date = date;
        const dateVal = new Date(this.value);
        const today = this.value instanceof Date ? this.value : (isNaN(dateVal) ? new Date() : dateVal);
        this.current_date = {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate(),
            hour: today.getHours(),
            minute: today.getMinutes()
        };
        this.$nextTick(() => {
            this.update()
        })
    },
    methods: {
        dateChange(e, key) {
            this.current_date[key] = e.target.value;
            this.update()
        },
        update(){
            const dateStr = this.current_date.year + '/' + this.current_date.month + '/' + this.current_date.day + ' ' + this.current_date.hour + ':' + this.current_date.minute + ':00'
            this.$emit('input', new Date(dateStr));
        }
    }
}
</script>

<style lang="scss" scoped>
.date {
    font-size: 13px;

    &-item {
        display: flex;
        align-items: center;

        &:nth-child(2) {
            margin-top: 10px;
        }
    }

    &-input {
        width: 70px;
        text-align: center;

        select {
            text-align: center;
            font-size: 13px;
        }
    }

    &-hr {
        width: 26px;
        text-align: center;
    }
}
</style>