import Vue from 'vue';
import App from './App.vue';
import popup from './assets/js/popup.js'

import {
  timeAgo,
  formatDate
} from "./assets/js/common";

Vue.config.productionTip = false


// 定义一个全局过滤器 补位
Vue.filter('padStart', function (value) {
  return value < 10 ? `0${value}` : value;
});

// 定义一个全局过滤器 个性时间
Vue.filter('timeago', function (value) {
  let ret = timeAgo(value);
  return ret;
});

// 定义一个全局过滤器 格式化时间
Vue.filter('formatDate', function (value) {
  return formatDate(value, 'yyyy年MM月dd日 HH:mm');
});


new Vue({
  render: h => h(App),
}).$mount('#app')


Vue.prototype.$popup = popup;

// 保存个人信息
Vue.prototype.saveMyInfo = (key, value) => {
  let info = window.localStorage.getItem("__myInfo__") || "{}";
  try {
    info = JSON.parse(info);
  } catch (error) {
    info = {};
  }
  info[key] = value;
  window.localStorage.setItem("__myInfo__", JSON.stringify(info));
}

// 拿到个人信息
Vue.prototype.getMyInfo = () => {
  let info = window.localStorage.getItem("__myInfo__") || "{}";
  try {
    info = JSON.parse(info);
  } catch (error) {
    info = {};
  }
  return info;
}

// 判断是否是微信浏览器
Vue.prototype.isWechat = () => {
  let ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger" && ua.match(/windowswechat/i) == null) {
    return true;
  } else {
    return false;
  }
}
