<template>
  <div>
    <!-- <header v-if="!iswx">
      <div class="header">老胡朋友圈截图生成器 Ver3.6</div>
    </header> -->
    <div class="container">
      <div class="console">
        <div class="card">
          <div class="model">
            <div class="model-item">场景</div>
            <div class="model-item" :class="{ active: param.build == 0 }" @click="param.build = 0">
              详情
            </div>
            <div class="model-item" :class="{ active: param.build == 1 }" @click="param.build = 1">
              圈内
            </div>
            <div class="model-item" :class="{ active: param.build == 2 }" @click="param.build = 2">
              封面
            </div>
          </div>
          <div class="cover" v-if="param.build == 2">
            <label for="cover-file">
              <div class="cover-text">
                点击更换背景图片
              </div>
              <img :src="baseinfo.cover" />
            </label>
            <input id="cover-file" type="file" style="display: none" @change="coverChange" accept="image/*" />
          </div>
          <div class="baseinfo">
            <label for="avatar-file" class="baseinfo-avatar">
              <img :src="baseinfo.avatar" />
            </label>
            <input id="avatar-file" type="file" style="display: none" @change="avatarChange" accept="image/*" />
            <div class="baseinfo-content">
              <div class="baseinfo-content-name" @click="nicknameClick">
                {{ baseinfo.nickname || "点击修改昵称" }}
              </div>
              <div class="baseinfo-content-value">
                <div class="baseinfo-content-value-text" @click="contentClick">
                  {{ baseinfo.content || "点击修改动态内容" }}
                </div>
                <div class="baseinfo-content-value-tabbar">
                  <div class="baseinfo-content-value-tabbar-item" :class="{ active: baseinfo.attr == 0 }"
                    @click="baseinfo.attr = 0">
                    <svg t="1726645405003" class="icon" viewBox="0 0 1128 1024" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" p-id="9379" width="16" height="16">
                      <path
                        d="M165.789 99.167h783c22.5 0 40.5 18 40.5 40.5v319.5c-40.5-31.5-117-81-175.5-81-85.5 0-162 198-256.5 198-72-4.5-171-85.5-283.5-72-45 9-108 81-148.5 135v-499.5c0-22.5 18-40.5 40.5-40.5zM354.788 423.167c-36 0-67.5-13.5-90-36s-36-58.5-36-90c0-31.5 13.5-67.5 36-90 22.5-22.5 54-36 90-36 31.5 0 63 13.5 90 36 22.5 22.5 36 58.5 36 90 0 31.5-13.5 67.5-36 90-27 22.5-58.5 36-90 36zM971.288 40.667h-828c-45 0-81 36-81 81v738c0 45 36 81 81 81h828c45 0 81-36 81-81v-738c0-45-36-81-81-81z"
                        p-id="9380" fill="#5b6a93"></path>
                    </svg>
                    图片
                  </div>
                  <div class="baseinfo-content-value-tabbar-item" :class="{ active: baseinfo.attr == 1 }"
                    @click="baseinfo.attr = 1">
                    <svg t="1726645231206" class="icon" viewBox="0 0 1024 1024" version="1.1"
                      xmlns="http://www.w3.org/2000/svg" p-id="5527" width="16" height="16">
                      <path
                        d="M607.934444 417.856853c-6.179746-6.1777-12.766768-11.746532-19.554358-16.910135l-0.01228 0.011256c-6.986111-6.719028-16.47216-10.857279-26.930349-10.857279-21.464871 0-38.864146 17.400299-38.864146 38.864146 0 9.497305 3.411703 18.196431 9.071609 24.947182l-0.001023 0c0.001023 0.001023 0.00307 0.00307 0.005117 0.004093 2.718925 3.242857 5.953595 6.03853 9.585309 8.251941 3.664459 3.021823 7.261381 5.997598 10.624988 9.361205l3.203972 3.204995c40.279379 40.229237 28.254507 109.539812-12.024871 149.820214L371.157763 796.383956c-40.278355 40.229237-105.761766 40.229237-146.042167 0l-3.229554-3.231601c-40.281425-40.278355-40.281425-105.809861 0-145.991002l75.93546-75.909877c9.742898-7.733125 15.997346-19.668968 15.997346-33.072233 0-23.312962-18.898419-42.211381-42.211381-42.211381-8.797363 0-16.963347 2.693342-23.725354 7.297197-0.021489-0.045025-0.044002-0.088004-0.066515-0.134053l-0.809435 0.757247c-2.989077 2.148943-5.691629 4.669346-8.025791 7.510044l-78.913281 73.841775c-74.178443 74.229608-74.178443 195.632609 0 269.758863l3.203972 3.202948c74.178443 74.127278 195.529255 74.127278 269.707698 0l171.829484-171.880649c74.076112-74.17435 80.357166-191.184297 6.282077-265.311575L607.934444 417.856853z"
                        fill="#5b6a93" p-id="5528"></path>
                      <path
                        d="M855.61957 165.804257l-3.203972-3.203972c-74.17742-74.178443-195.528232-74.178443-269.706675 0L410.87944 334.479911c-74.178443 74.178443-78.263481 181.296089-4.085038 255.522628l3.152806 3.104711c3.368724 3.367701 6.865361 6.54302 10.434653 9.588379 2.583848 2.885723 5.618974 5.355985 8.992815 7.309476 0.025583 0.020466 0.052189 0.041956 0.077771 0.062422l0.011256-0.010233c5.377474 3.092431 11.608386 4.870938 18.257829 4.870938 20.263509 0 36.68962-16.428158 36.68962-36.68962 0-5.719258-1.309832-11.132548-3.645017-15.95846l0 0c-4.850471-10.891048-13.930267-17.521049-20.210297-23.802102l-3.15383-3.102664c-40.278355-40.278355-24.982998-98.79612 15.295358-139.074476l171.930791-171.830507c40.179095-40.280402 105.685018-40.280402 145.965419 0l3.206018 3.152806c40.279379 40.281425 40.279379 105.838513 0 146.06775l-75.686796 75.737962c-10.296507 7.628748-16.97358 19.865443-16.97358 33.662681 0 23.12365 18.745946 41.87062 41.87062 41.87062 8.048303 0 15.563464-2.275833 21.944801-6.211469 0.048095 0.081864 0.093121 0.157589 0.141216 0.240477l1.173732-1.083681c3.616364-2.421142 6.828522-5.393847 9.529027-8.792247l79.766718-73.603345C929.798013 361.334535 929.798013 239.981676 855.61957 165.804257z"
                        fill="#5b6a93" p-id="5529"></path>
                    </svg>
                    链接
                  </div>
                </div>
                <div class="baseinfo-content-value-picture" v-if="baseinfo.attr == 0">
                  <draggable class="picture-group" v-model="baseinfo.pictures" group="people" @start="drag = true"
                    @end="dragEnd" draggable=".draggable-item">
                    <div class="picture-group-item draggable-item" v-for="(item, index) in baseinfo.pictures"
                      :key="index" :style="{ backgroundImage: `url('${item}')` }">
                      <div class="picture-group-item-delete" @click="pictureDelete(index)">
                        ╳
                      </div>
                    </div>

                    <label class="picture-group-item" v-if="baseinfo.pictures.length < 9"
                      style="background-image: url('./icons/upload.png')" for="upload-picture"></label>
                  </draggable>
                  <input type="file" id="upload-picture" style="display: none" @change="pictureChange" accept="image/*"
                    multiple />
                </div>
                <div class="baseinfo-content-value-link" v-if="baseinfo.attr == 1">
                  <label for="linkcover-file" class="baseinfo-content-value-link-cover">
                    <img :src="baseinfo.link.cover" />
                    <input id="linkcover-file" type="file" style="display: none" @change="linkCoverChange"
                      accept="image/*" />
                  </label>
                  <div class="baseinfo-content-value-link-title" @click="linktitleClick">
                    {{ baseinfo.link.title || "点我修改标题" }}
                  </div>
                </div>
              </div>
              <div class="baseinfo-content-other">
                <div class="baseinfo-content-other-date">
                  <div class="baseinfo-content-other-date-value" @click="dateClick">
                    {{ baseinfoDate }}
                  </div>
                  <!-- <div class="baseinfo-content-other-date-delete">
                    <img src="/icons/delete.png" />
                  </div> -->
                </div>
                <div class="baseinfo-content-other-option">
                  <div class="baseinfo-content-other-option-item" @click="likeClick">
                    <img src="/icons/like.png" />
                    点赞
                  </div>
                  <div class="baseinfo-content-other-option-item" @click="commentClick(null)">
                    <img src="/icons/comment.png" />
                    评论
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="interactive">
            <div class="like" v-if="likes.length">
              <div class="like-icon">
                <img src="/icons/like.png" />
              </div>
              <div class="like-warp">
                <div class="like-item" v-for="(item, index) in likes" :key="index" @click="deleteLike(index)">
                  <img :src="item.avatar" />
                </div>
                <div class="like-item like-item-delete" @click="likes = []">
                  清空点赞
                </div>
              </div>
            </div>
            <div class="comment" v-if="comments.length">
              <div class="comment-icon">
                <img src="/icons/comment.png" />
              </div>
              <div class="comment-warp">
                <div class="comment-item" v-for="(item, index) in comments" :key="index" @click="commentClick(item)">
                  <div class="comment-item-avatar">
                    <img :src="item.avatar" />
                  </div>
                  <div class="comment-item-info">
                    <div class="comment-item-info-header">
                      <div class="comment-item-info-nickname">
                        {{ item.nickname }}
                      </div>
                      <div class="comment-item-info-date">
                        {{ item.date | formatDate }}
                      </div>
                    </div>
                    <div class="comment-item-info-content">
                      <div class="comment-item-info-content-quote" v-if="item.quote">
                        回复<span>{{ item.quote.nickname }}</span>
                      </div>
                      {{ item.content }}
                      <span class="comment-item-info-content-delete" @click.stop="deleteComment(index)">删除</span>
                    </div>
                  </div>
                </div>
                <div class="comment-item comment-item-delete" @click="comments = []">
                  清空评论
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="param">
            <div class="param-title">控制台</div>
            <div class="param-group">
              <div class="param-item param-noflex" style="width: 70%">
                <div class="param-item-title">手机时间</div>
                <div class="param-item-content">
                  <date-select type="time" v-model="param.time"></date-select>
                </div>
              </div>
              <div class="param-item param-noflex" style="width: 30%">
                <div class="param-item-title">手机电量</div>
                <div class="param-item-content">
                  <div class="param-item-content-value">
                    <input type="text" v-model="param.power" />
                  </div>
                  <div class="param-item-content-value param-noflex">
                    <span style="margin-left: 5px">%</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="param-group">
              <div class="param-item">
                <div class="param-item-title">选择系统</div>
                <div class="param-item-content">
                  <div class="radio-group param-item-content-value">
                    <div class="radio-group-item" :class="{ active: param.system == 'android' }"
                      @click="param.system = 'android'">
                      安卓
                    </div>
                    <div class="radio-group-item" :class="{ active: param.system == 'ios' }"
                      @click="param.system = 'ios'">
                      苹果
                    </div>
                  </div>
                </div>
              </div>
              <div class="param-item">
                <div class="param-item-title">深色模式</div>
                <div class="param-item-content">
                  <div class="radio-group param-item-content-value">
                    <div class="radio-group-item" :class="{ active: !param.dark }" @click="param.dark = false">
                      关闭
                    </div>
                    <div class="radio-group-item" :class="{ active: param.dark }" @click="param.dark = true">
                      打开
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="param-group">
              <div class="param-item">
                <div class="param-item-title">是否连接WIFI</div>
                <div class="param-item-content">
                  <div class="radio-group param-item-content-value">
                    <div class="radio-group-item" :class="{ active: param.wifi }" @click="param.wifi = true">
                      是
                    </div>
                    <div class="radio-group-item" :class="{ active: !param.wifi }" @click="param.wifi = false">
                      否
                    </div>
                  </div>
                </div>
              </div>
              <div class="param-item">
                <div class="param-item-title">网络</div>
                <div class="param-item-content">
                  <div class="radio-group param-item-content-value">
                    <div class="radio-group-item" :class="{ active: param.network == '5G' }"
                      @click="param.network = '5G'">
                      5G
                    </div>
                    <div class="radio-group-item" :class="{ active: param.network == '4G' }"
                      @click="param.network = '4G'">
                      4G
                    </div>
                    <div class="radio-group-item" :class="{ active: param.network == '2G' }"
                      @click="param.network = '2G'">
                      2G
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="param-group">
              <div class="param-item">
                <div class="param-item-title">是否充电</div>
                <div class="param-item-content">
                  <div class="radio-group param-item-content-value">
                    <div class="radio-group-item" :class="{ active: !param.charge }" @click="param.charge = false">
                      否
                    </div>
                    <div class="radio-group-item" :class="{ active: param.charge }" @click="param.charge = true">
                      是
                    </div>
                  </div>
                </div>
              </div>
              <div class="param-item">
                <div class="param-item-title">截图方式</div>
                <div class="param-item-content">
                  <div class="radio-group param-item-content-value">
                    <div class="radio-group-item" :class="{ active: param.screenshot == 0 }"
                      @click="param.screenshot = 0">
                      截屏幕
                    </div>
                    <div class="radio-group-item" :class="{ active: param.screenshot == 1 }"
                      @click="param.screenshot = 1">
                      截长图
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="param-group">
              <div class="param-item">
                <div class="param-item-title">一行几个赞</div>
                <div class="param-item-content">
                  <div class="radio-group param-item-content-value">
                    <div class="radio-group-item" :class="{ active: param.likenum == 0 }" @click="param.likenum = 0">
                      7个
                    </div>
                    <div class="radio-group-item" :class="{ active: param.likenum == 1 }" @click="param.likenum = 1">
                      8个
                    </div>
                    <div class="radio-group-item" :class="{ active: param.likenum == 2 }" @click="param.likenum = 2">
                      9个
                    </div>
                    <div class="radio-group-item" :class="{ active: param.likenum == 3 }" @click="param.likenum = 3">
                      10个
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ad">
              <img src="/ad/ad1.png" style="width: 100%" />
            </div>
          </div>
        </div>
      </div>
      <div class="preview card" ref="preview">
        <div class="preview-title">
          <div>实时预览</div>
          <div v-if="iswx" style="flex: 1">
            <span class="preview-title-tip">(长按截图保存到相册或发送给好友)</span>
          </div>
          <div v-else style="text-align: right; flex: 1">
            <span class="preview-title-button" @click="download">
              <svg t="1724176921763" class="icon" viewBox="0 0 1228 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="6208" width="16" height="16">
                <path
                  d="M528.00000031 692.48a127.99999969 127.99999969 0 0 0 157.44 0l248.95999969-192a64.00000031 64.00000031 0 0 0-78.72-101.11999969L672.00000031 543.99999969V96.00000031a64.00000031 64.00000031 0 0 0-128.00000062 0v447.99999938L357.75999969 397.44000031a64.00000031 64.00000031 0 0 0-78.72 101.11999969z"
                  fill="#364875" p-id="6209"></path>
                <path
                  d="M1119.99999969 608a64.00000031 64.00000031 0 0 0-63.99999938 64.00000031v127.99999969a64.00000031 64.00000031 0 0 1-64.00000031 64.00000031H224a64.00000031 64.00000031 0 0 1-64.00000031-64.00000031v-127.99999969a64.00000031 64.00000031 0 0 0-127.99999969 0v192a127.99999969 127.99999969 0 0 0 127.99999969 127.99999969h896.00000062a127.99999969 127.99999969 0 0 0 127.99999969-127.99999969v-192a64.00000031 64.00000031 0 0 0-64.00000031-64.00000031z"
                  fill="#364875" p-id="6210"></path>
              </svg>
              下载截图
            </span>
          </div>
        </div>
        <div class="preview-canvas">
          <pre-view :data="dataParam" ref="previewCanvas"></pre-view>
        </div>
      </div>
    </div>
    <div class="preview-tip" :class="{ 'preview-tip-show': show_preview_tip }" @click="scrollPreview">
      <svg t="1724170048941" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
        p-id="7558" width="13" height="13">
        <path
          d="M159.417291 157.449985l352.753089 343.768461 357.257683-345.263511 41.97602 1.49505-399.233704 422.216137-399.221424-422.216137L159.417291 157.449985zM159.417291 438.754812l352.753089 343.769484L869.428064 437.763229l41.97602 0.991584-399.233704 422.717557-399.221424-422.717557L159.417291 438.754812z"
          p-id="7559" fill="#ffffff"></path>
      </svg>
      实时预览
    </div>
    <!-- 占位 -->
    <div class="tabbar-spa"></div>
    <div class="tabbar">
      <div class="tabbar-item" :class="{ active: tabbar_index == 0 }" @click="tabbarClick(0)">
        <svg t="1726989506304" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7148"
          width="25" height="25">
          <path
            d="M687.641075 400.367143 687.641075 115.759507c0 0-139.301154-74.620207-301.894516-17.070826L687.641075 400.367143zM714.042575 567.763249 714.042575 127.520733c0 0 164.685143 74.620207 211.100074 255.832945L714.042575 567.763249zM625.453039 695.708302l303.986297-285.749462c0 0 53.820909 87.409137-26.4017 285.749462L625.453039 695.708302zM470.209094 731.962322l410.610606 0c0 0-49.580784 134.339318-226.986303 189.71937L470.209094 731.962322zM336.165776 641.298791l0 267.53681c0 0 106.624109 68.282904 289.287263 22.437387L336.165776 641.298791zM98.60744 655.172385l207.990582-186.579299 0 424.256754C306.598222 892.84984 180.978393 849.230814 98.60744 655.172385zM93.349805 625.31283c0 0-47.545564-153.46555 18.995521-286.720004l286.064848 0L93.349805 625.31283zM130.323337 308.67611c0 0 60.152816-136.394724 223.763688-200.395732l195.270207 200.395732L130.323337 308.67611z"
            fill="#272636" p-id="7149"></path>
        </svg>
        <div class="tabbar-item-text">动态</div>
      </div>
      <div class="tabbar-item" :class="{ active: tabbar_index == 1 }" @click="tabbarClick(1)">
        <svg t="1726989912760" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="35105" width="20" height="20">
          <path
            d="M874.496 1024H149.504C66.56 1024 0 957.44 0 874.496V149.504C0 66.56 66.56 0 149.504 0h724.992C957.44 0 1024 66.56 1024 149.504v724.992c0 82.944-66.56 149.504-149.504 149.504zM149.504 84.992c-35.84 0-64.512 27.648-64.512 64.512v724.992c0 35.84 27.648 64.512 64.512 64.512h724.992c35.84 0 64.512-27.648 64.512-64.512V149.504c0-35.84-27.648-64.512-64.512-64.512H149.504z"
            p-id="35106"></path>
          <path
            d="M770.048 311.296H727.04c-17.408-38.912-53.248-64.512-98.304-64.512-45.056 0-80.896 25.6-98.304 64.512h-276.48c-23.552 0-40.96 19.456-40.96 40.96v2.048c0 23.552 19.456 40.96 40.96 40.96h277.504c17.408 38.912 53.248 64.512 98.304 64.512 45.056 0 80.896-25.6 98.304-64.512h43.008c23.552 0 40.96-19.456 40.96-40.96v-2.048c-1.024-21.504-20.48-40.96-41.984-40.96z m0 320.512H492.544c-17.408-38.912-53.248-64.512-98.304-64.512s-80.896 25.6-98.304 64.512h-43.008c-23.552 0-40.96 19.456-40.96 40.96v2.048c0 23.552 19.456 40.96 40.96 40.96H296.96c17.408 38.912 53.248 64.512 98.304 64.512s80.896-25.6 98.304-64.512h277.504c23.552 0 40.96-19.456 40.96-40.96v-2.048c-1.024-22.528-20.48-40.96-41.984-40.96z"
            p-id="35107"></path>
        </svg>
        <div class="tabbar-item-text">控制台</div>
      </div>
      <div class="tabbar-item" :class="{ active: tabbar_index == 2 }" @click="tabbarClick(2)">
        <svg t="1726989953470" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
          p-id="37127" width="20" height="20">
          <path
            d="M512 642.9c81.6 0 157.6-42.3 229.5-130.9-72-88.6-147.9-130.9-229.5-130.9S354.4 423.4 282.5 512c71.9 88.6 147.9 130.9 229.5 130.9z m0 62.3c-105.3 0-201.4-53.6-288.5-160.7-15.4-18.9-15.4-46 0-64.9C310.6 372.4 406.7 318.8 512 318.8s201.4 53.6 288.5 160.7c15.4 18.9 15.4 46 0 64.9C713.4 651.6 617.3 705.2 512 705.2z m0 0"
            fill="#333333" p-id="37128"></path>
          <path
            d="M512 540c15.5 0 28-12.5 28-28 0-15.4-12.5-28-28-28s-28 12.5-28 28c0 15.4 12.5 28 28 28z m0 71.9c-35.7 0-68.7-19-86.6-49.9-17.9-30.9-17.9-69 0-99.9 17.9-30.9 50.9-49.9 86.6-49.9 55.2 0 100 44.7 100 99.9 0 55.1-44.8 99.8-100 99.8z m0 0"
            fill="#333333" p-id="37129"></path>
          <path
            d="M136 888V745c0-19.9-16.1-36-36-36s-36 16.1-36 36v155c0 33.1 26.9 60 60 60h155c19.9 0 36-16.1 36-36s-16.1-36-36-36H136zM136 136h143c19.9 0 36-16.1 36-36s-16.1-36-36-36H124c-33.1 0-60 26.9-60 60v155c0 19.9 16.1 36 36 36s36-16.1 36-36V136zM888 136v143c0 19.9 16.1 36 36 36s36-16.1 36-36V124c0-33.1-26.9-60-60-60H745c-19.9 0-36 16.1-36 36s16.1 36 36 36h143zM888 888H745c-19.9 0-36 16.1-36 36s16.1 36 36 36h155c33.1 0 60-26.9 60-60V745c0-19.9-16.1-36-36-36s-36 16.1-36 36v143z"
            fill="#333333" p-id="37130"></path>
        </svg>
        <div class="tabbar-item-text">预览</div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import DateSelect from "./Date.vue";
import { formatDate, getRandomTimeRange } from "../assets/js/common";
import PreView from "./PreView.vue";
import { getRandomUsers, getRandomReply } from "../assets/js/fakedata.js";
import cropper from "../assets/js/cropper.js";
export default {
  name: "Home",
  components: {
    draggable,
    DateSelect,
    PreView,
  },
  data() {
    return {
      tabbar_index: 0,
      baseinfo: {
        avatar: "/icons/default-avatar.jpg",
        nickname: "",
        content: "",
        date: "",
        // 0图片  1链接
        attr: 0,
        pictures: [],
        link: {
          cover: "/icons/link.png",
          title: "",
        },
        cover: "/images/bg.jpg",
      },
      param: {
        time: "",
        power: 80,
        system: "android",
        wifi: true,
        dark: false,
        build: 0,
        network: "5G",
        charge: false,
        screenshot: 0,
        likenum: 3,
      },
      likes: [],
      comments: [],
      show_preview_tip: false,
      iswx: false,
    };
  },
  computed: {
    baseinfoDate() {
      if (!this.baseinfo.date) {
        return "点击修改时间(默认刚刚)";
      }
      return formatDate(this.baseinfo.date, "yyyy年MM月dd日 HH:mm");
    },
    dataParam() {
      return {
        baseinfo: this.baseinfo,
        param: this.param,
        likes: this.likes,
        comments: this.comments,
      };
    },
  },
  created() {
    this.$nextTick(() => {
      this.iswx = this.isWechat();
      // 恢复个人信息
      const myInfo = this.getMyInfo();
      this.baseinfo = { ...this.baseinfo, ...myInfo };

      // 监听滚动条
      const checkPreviewTip = () => {
        const scrollTop = document.documentElement.scrollTop;
        const previewTop =
          (this.$refs.preview?.offsetTop || 0) - scrollTop + 100;
        // 显示预览提示
        this.show_preview_tip = previewTop > window.innerHeight;
      };
      window.addEventListener("scroll", checkPreviewTip);
      checkPreviewTip();

      this.els = [
        document.querySelector(".console"),
        document.querySelector(".param"),
        document.querySelector(".preview"),
      ];
      // 监听页面滚动事件
      window.addEventListener("scroll", () => {
        for (let index = this.els.length - 1; index >= 0; index--) {
          const element = this.els[index];
          // 获取目标元素相对于视口顶部的距离
          var elementTop = element.getBoundingClientRect().top;
          // 获取视口高度
          var viewportHeight = window.innerHeight;

          // 判断元素的 top 是否小于视口高度的一半
          if (elementTop < viewportHeight / 3) {
            // 执行触发的操作
            this.tabbar_index = index;
            break;
          }
        }
      });

      this.$popup({
        type: "text",
        content: `
        <section data-v-369aa167="" id="output">
          <h3 style="text-align:left;line-height:1.2;font-family:-apple-system-font,BlinkMacSystemFont, Helvetica Neue, PingFang SC, Hiragino Sans GB , Microsoft YaHei UI , Microsoft YaHei ,Arial,sans-serif;font-size:14px;font-weight:bold;margin:2em 8px 0.75em 0;padding-left:8px;border-left:3px solid rgba(15, 76, 129, 1);color:#3f3f3f;margin-top: 0">
            2024-09-22 更新日志
          </h3>
          <ul style="text-align:left;line-height:1.75;font-family:-apple-system-font,BlinkMacSystemFont, Helvetica Neue, PingFang SC, Hiragino Sans GB , Microsoft YaHei UI , Microsoft YaHei ,Arial,sans-serif;font-size:14px;margin-left:0;padding-left:1em;list-style:circle;color:#3f3f3f">
            <li style="text-align:left;line-height:1.75;text-indent:-1em;display:block;margin:0.2em 8px;color:#3f3f3f">
              <span>• </span>新增封面场景，界面大更新。
            </li>
            <li style="text-align:left;line-height:1.75;text-indent:-1em;display:block;margin:0.2em 8px;color:#3f3f3f">
              <span>• </span>增加一行显示几个赞功能，可根据自己喜好调整。
            </li>
          </ul>
          <p style="text-align:justify;line-height:1.75;font-family:-apple-system-font,BlinkMacSystemFont, Helvetica Neue, PingFang SC, Hiragino Sans GB , Microsoft YaHei UI , Microsoft YaHei ,Arial,sans-serif;font-size:14px;margin:1.5em 8px;letter-spacing:0.1em;color:#3f3f3f;margin-bottom: 0;">如需修改或增加功能请在<strong style="text-align:left;line-height:1.75;color:rgba(15, 76, 129, 1);font-weight:bold">【老胡有招】</strong>公众号内留言。</p>
          <style>
            .hljs.code__pre::before {
              position: initial;
              padding: initial;
              content: '';
              display: block;
              height: 25px;
              background-color: transparent;
              background-image: url("https://doocs.oss-cn-shenzhen.aliyuncs.com/img/123.svg");
              background-position: 14px 10px!important;
              background-repeat: no-repeat;
              background-size: 40px!important;
            }

            .hljs.code__pre {
              padding: 0!important;
            }

            .hljs.code__pre code {
              display: -webkit-box;
              padding: 0.5em 1em 1em;
              overflow-x: auto;
              text-indent: 0;
            }
          </style>
        </section>
        `,
        show_close: false,
        mask_close: true,
        confirm_text: "朕知道了",
        confirm: (value, close) => {
          close();
        },
      });
    });
  },
  methods: {
    tabbarClick(index) {
      this.tabbar_index = index;
      // 获取目标元素距离页面顶部的距离
      var elementTop =
        this.els[index].getBoundingClientRect().top + window.pageYOffset;
      // 滚动到目标元素，并留出 100 像素的间距
      window.scrollTo({
        top: elementTop - 100, // 距离顶部 100 像素
        behavior: "smooth", // 平滑滚动
      });
    },
    // 上传封面
    coverChange(e) {
      const file = e.target.files[0];
      const blobURL = URL.createObjectURL(file);
      cropper({
        title: '封面裁剪',
        img: blobURL,
        fixedNumber: [1.395, 1],
        confirm: (data) => {
          this.baseinfo.cover = data;
        },
      });
    },
    // 上传头像
    avatarChange(e) {
      const file = e.target.files[0];
      const blobURL = URL.createObjectURL(file);
      cropper({
        img: blobURL,
        confirm: (data) => {
          this.baseinfo.avatar = data;
          this.saveMyInfo("avatar", data);
        },
      });
    },
    nicknameClick() {
      this.$popup({
        type: "input",
        content: "请输入昵称",
        inputValue: this.baseinfo.nickname,
        title: "修改昵称",
        confirm: (value, close) => {
          if (!value) {
            msg("昵称不能为空");
            return;
          }
          this.baseinfo.nickname = value;
          this.saveMyInfo("nickname", value);
          close();
        },
      });
    },
    contentClick() {
      this.$popup({
        type: "textarea",
        content: "请输入动态内容",
        inputValue: this.baseinfo.content,
        title: "修改动态内容",
        confirm: (value, close) => {
          if (!value) {
            msg("动态内容不能为空");
            return;
          }
          this.baseinfo.content = value;
          close();
        },
      });
    },
    pictureDelete(index) {
      this.$popup({
        content: "确定要删除这个图片吗？",
        title: "询问",
        confirm: (value, close) => {
          this.baseinfo.pictures.splice(index, 1);
          close();
        },
      });
    },
    dateClick() {
      this.$popup({
        type: "date",
        content: "请选择日期",
        inputValue: this.baseinfo.date,
        title: "发布日期",
        confirm: (value, close) => {
          if (!value) {
            msg("日期不能为空");
            return;
          }
          this.baseinfo.date = value;
          close();
        },
      });
    },
    timeChange(e, key) {
      this.param.time[key] = e.target.value;
    },
    pictureChange(e) {
      const fileList = e.target.files;
      const len = 9 - this.baseinfo.pictures.length;
      for (let index = 0; index < len; index++) {
        try {
          const i = fileList[index];
          this.baseinfo.pictures.push(URL.createObjectURL(i));
        } catch (error) {
          break;
        }
      }
    },
    dragEnd() { },

    likeClick() {
      this.show_option = false;
      this.$popup({
        type: "like",
        title: "点赞",
        inputValue: {
          avatar: "",
          nickname: "",
          num: "",
          tab: 0,
          insert: 2,
        },
        confirm: (value, close) => {
          let list = [];
          // 如果是手动
          if (value.tab == 1) {
            if (!value.avatar) {
              msg("头像不能为空");
              return;
            }
            if (!value.nickname) {
              msg("昵称不能为空");
              return;
            }
            list.push(value);
          } else {
            // 随机
            if (!value.num) {
              msg("生成数量不能为空");
              return;
            }
            const uinfos = getRandomUsers(parseInt(value.num));
            list = uinfos.map((item) => item);
          }
          if (value.insert == 0) {
            this.likes = list;
          } else if (value.insert == 1) {
            this.likes = [...list, ...this.likes];
          } else if (value.insert == 2) {
            this.likes = [...this.likes, ...list];
          }
          close();
        },
      });
    },
    commentClick(quote) {
      this.show_option = false;
      this.$popup({
        type: "comment",
        title: quote ? "回复 " + quote.nickname : "评论",
        inputValue: {
          avatar: "",
          content: "",
          nickname: "",
          num: "",
          tab: 0,
          insert: 1,
          quote,
          startDate: this.baseinfo.date,
          endDate: "",
        },
        confirm: (value, close) => {
          let list = [];
          // 如果是手动
          if (value.tab == 1) {
            if (!value.avatar) {
              msg("头像不能为空");
              return;
            }
            if (!value.nickname) {
              msg("昵称不能为空");
              return;
            }
            if (!value.content) {
              msg("内容不能为空");
              return;
            }
            list.push({
              ...value,
              date: value.startDate,
            });
          } else {
            // 随机
            if (!value.num) {
              msg("生成数量不能为空");
              return;
            }
            const uinfos = getRandomUsers(parseInt(value.num));
            // 评论开始时间
            const start = value.startDate;
            // 结束时间
            let end = value.endDate;
            // 如果结束时间小于开始时间
            if (end < start) {
              end = new Date(start.getTime() + 60 * 60 * 24 * 1000);
            }
            console.log(start, end);
            uinfos.map((item) => {
              list.push({
                ...item,
                content: getRandomReply(),
                date: getRandomTimeRange(start, end),
                quote,
              });
            });
          }
          if (value.insert == 1 || value?.quote) {
            list = [...list, ...this.comments];
          }
          // 根据时间排序
          list = list.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          });
          this.comments = list;
          close();
        },
      });
    },
    deleteLike(index) {
      this.$popup({
        content: "确定要删除这个点赞吗？",
        title: "询问",
        confirm: (value, close) => {
          this.likes.splice(index, 1);
          close();
        },
      });
    },
    deleteComment(index) {
      this.$popup({
        content: "确定要删除这个评论吗？",
        title: "询问",
        confirm: (value, close) => {
          this.comments.splice(index, 1);
          close();
        },
      });
    },
    scrollPreview() {
      window.scrollTo({
        top: this.$refs.preview.offsetTop - 60,
        behavior: "smooth",
      });
    },
    // 点击按钮时，触发下载功能
    download() {
      const canvas = this.$refs.previewCanvas.getCanvas();
      // 将 Canvas 内容转换为数据URL（图片格式）
      const dataURL = canvas.toDataURL("image/png");

      // 创建一个临时的 a 元素用于触发下载
      const link = document.createElement("a");
      link.href = dataURL;
      link.download =
        "朋友圈截图" + formatDate(new Date(), "MMddHHmm") + ".png"; // 设置下载文件名
      link.click(); // 触发点击事件进行下载
    },
    linkCoverChange(e) {
      const file = e.target.files[0];
      const blobURL = URL.createObjectURL(file);
      cropper({
        img: blobURL,
        title: '封面裁剪',
        confirm: (data) => {
          this.baseinfo.link.cover = data;
        },
      });
    },
    linktitleClick() {
      this.$popup({
        type: "input",
        content: "请输入链接标题",
        inputValue: this.baseinfo.link.title,
        title: "修改标题",
        confirm: (value, close) => {
          if (!value) {
            msg("标题不能为空");
            return;
          }
          this.baseinfo.link.title = value;
          close();
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  background-color: #fff;
  box-shadow: 0 0 4px rgba($color: #000000, $alpha: 0.2);
  position: sticky;
  z-index: 2;
  top: 0;

  .header {
    margin: 0 auto;
    max-width: 1000px;
    display: flex;
    align-items: center;
    height: 60px;
  }
}

@media screen and (max-width: 768px) {
  header {
    .header {
      padding: 0 20px;
    }
  }

  .container {
    display: block !important;

    .preview {
      margin-left: 0 !important;
    }
  }
}

.container {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  padding: 15px;
  padding-bottom: 0;
  box-sizing: border-box;

  .preview {
    margin-left: 20px;
  }
}

.model {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  top: 0;
  z-index: 10;
  height: 50px;
  padding-bottom: 10px;
  margin-bottom: 14px;
  border-bottom: solid 1px #f1f1f1;

  &-item {
    padding: 10px 18px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;

    &:first-child {
      color: #666;
    }

    &.active {
      background-color: var(--wx-color);
      color: #fff;
    }
  }
}

.card {
  border-radius: 10px;
  background-color: rgba($color: #ffffff, $alpha: 0.9);
  overflow: hidden;
  margin-bottom: 15px;
  padding: 14px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
}

.console {
  flex: 1;

  // 图片组
  .picture-group {
    display: flex;
    flex-wrap: wrap;
    --offset_left: 2px;
    margin-left: calc(var(--offset_left) * -1);
    --width: calc(33.333333333% - var(--offset_left));

    &-item {
      width: var(--width);
      padding-top: var(--width);
      margin-left: var(--offset_left);
      margin-bottom: var(--offset_left);
      background-size: cover;
      background-position: center;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      &-delete {
        position: absolute;
        top: 0;
        right: 0;
        width: 25px;
        height: 25px;
        // border-radius: 25px 0 25px 25px;
        font-size: 12px;
        background-color: rgba(255, 0, 0, 0.6);
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 1;

        &:active {
          background-color: rgba(255, 0, 0, 1);
        }
      }
    }
  }

  .cover {
    margin-bottom: 18px;
    position: relative;

    img {
      width: 100%;
      border-radius: 3px;
    }

    &-text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-shadow: 0 0 3px rgba($color: #000, $alpha: 1);
      color: #fff;
      font-size: 16px;
    }
  }

  .baseinfo {
    display: flex;

    &-title {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 10px;
      color: #666;
    }

    &-avatar {
      width: 44px;
      height: 44px;
      border-radius: 4px;
      overflow: hidden;
      display: block;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-content {
      flex: 1;
      margin-left: 8px;

      &-name {
        font-size: 14px;
        color: #002ba1;
        margin-bottom: 6px;
        cursor: pointer;
      }

      &-value {
        font-size: 15px;

        &-text {
          cursor: pointer;
        }

        &-tabbar {
          display: flex;
          margin: 20px 0;

          &-item {
            background-color: #f0f0fc;
            margin-right: 15px;
            font-size: 14px;
            padding: 8px 16px;
            border-radius: 3px;
            cursor: pointer;

            svg {
              vertical-align: middle;
            }

            &.active {
              background-color: var(--wx-color);
              color: #fff;

              path {
                fill: #fff;
              }
            }
          }
        }

        &-link {
          display: flex;
          padding: 4px;
          box-sizing: border-box;
          background-color: #f1f1f1;

          &-cover {
            margin-right: 10px;

            img {
              width: 40px;
              height: 40px;
              vertical-align: middle;
            }
          }

          &-title {
            flex: 1;
            font-size: 13px;
            display: flex;
            align-items: center;
          }
        }

        &-picture {
          // margin-top: 10px;
        }
      }

      &-other {
        margin-top: 15px;
        display: flex;
        align-items: center;

        &-date {
          font-size: 13px;
          color: #888;
          display: flex;
          flex: 1;

          &-value {
            cursor: pointer;
          }

          &-delete {
            margin-left: 8px;

            img {
              width: 12px;
              vertical-align: middle;
              margin-top: -3px;
            }
          }
        }

        &-option {
          display: flex;
          gap: 20px;

          &-item {
            padding: 10px 12px;
            background-color: #eeeeee;
            font-size: 13px;
            border-radius: 3px;
            cursor: pointer;

            img {
              width: 16px;
              height: 16px;
              vertical-align: sub;
            }

            &:hover {
              background-color: #e1e1f3 !important;
            }
          }
        }
      }
    }
  }

  // .date {
  //   display: flex;
  //   align-items: center;

  //   &-input {
  //     width: 80px;
  //   }

  //   &-hr {
  //     width: 18px;
  //     text-align: center;
  //   }
  // }

  .interactive {
    .like {
      border-radius: 5px;
      background-color: #f8f8f8;
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      gap: 6px;

      &-icon {
        padding-top: 5px;

        img {
          width: 20px;
          height: 20px;
        }
      }

      &-warp {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
      }

      &-item {
        width: 30px;
        height: 30px;
        border-radius: 3px;
        overflow: hidden;

        &-delete {
          width: auto !important;
          font-size: 12px;
          line-height: 30px;
          padding: 0 10px;
          color: var(--wx-color);

          &:active {
            color: #f00;
          }
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .comment {
      margin-top: 1px;
      background-color: #f8f8f8;
      padding: 10px;
      box-sizing: border-box;
      display: flex;
      gap: 6px;

      &-icon {
        padding-top: 5px;

        img {
          width: 20px;
          height: 20px;
        }
      }

      &-warp {
        flex: 1;
        width: 100%;
      }

      &-item {
        display: flex;
        width: 100%;
        margin-bottom: 15px;

        &-delete {
          width: auto !important;
          font-size: 12px;
          line-height: 30px;
          padding: 0 10px;
          color: var(--wx-color);
          text-align: right;
          display: block !important;

          &:active {
            color: #f00;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        &-avatar {
          width: 30px;
          height: 30px;
          border-radius: 3px;
          overflow: hidden;
          margin-right: 8px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &-info {
          line-height: 1;
          flex: 1;
          width: 100%;

          &-header {
            display: flex;
            width: 100%;
          }

          &-date {
            font-size: 11px;
            color: #777;
          }

          &-nickname {
            flex: 1;
            font-size: 12px;
            color: #002ba1;
            margin-bottom: 8px;
          }

          &-content {
            font-size: 14px;

            &-quote {
              display: inline-block;

              span {
                color: var(--wx-color);
                font-weight: 700;
                margin-left: 2px;
              }
            }

            &-delete {
              color: var(--wx-color);
              padding: 0 10px;
              cursor: pointer;

              &:active {
                color: #f00;
              }
            }
          }
        }
      }
    }
  }

  .param {
    &-title {
      font-size: 15px;
      font-weight: bold;
      color: #666;
    }

    &-noflex {
      flex: initial !important;
    }

    &-item {
      flex: 1;

      &-title {
        font-size: 12px;
        color: #666;
        margin-bottom: 6px;
      }

      &-content {
        font-size: 14px;
        display: flex;
        align-items: center;

        &-value {
          flex: 1;
        }
      }
    }

    &-group {
      margin: 20px 0;
      display: flex;
      gap: 10px;
    }

    &-button {
      background-color: var(--wx-color);
      color: #fff;
      height: 40px;
      line-height: 40px;
      border: solid 1px var(--wx-color);
      border-radius: 6px;
      text-align: center;
      font-size: 14px;
      cursor: pointer;

      &:active {
        opacity: 0.5;
      }
    }
  }
}

.preview {
  background-color: #fff;

  &-title {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 18px;
    margin-top: 6px;
    color: #666;
    display: flex;

    &-tip {
      font-size: 13px;
      margin-left: 10px;
      color: #ff5656;
    }

    &-button {
      color: var(--wx-color);
      font-size: 13px;
      border-radius: 30px;
      cursor: pointer;

      &:hover {
        color: #f00;

        path {
          fill: #f00;
        }
      }

      svg {
        vertical-align: middle;
      }
    }
  }

  &-canvas {
    text-align: center;
  }
}

@keyframes down {
  0% {
    transform: translateY(-2px);
  }

  50% {
    transform: translateY(2px);
  }

  100% {
    transform: translateY(0);
  }
}

.preview-tip {
  position: fixed;
  z-index: 10;
  left: 50%;
  bottom: -60px;
  font-size: 13px;
  transform: translateX(-50%);
  color: #fff;
  background-color: #393e46;
  padding: 0 20px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  gap: 5px;
  opacity: 0.8;
  transition: all 0.3s;

  svg {
    transform: translateY(-2px);
    animation: down 0.8s infinite linear;
  }

  &-show {
    bottom: 80px;
  }

  &:active {
    opacity: 1;
  }
}

.tabbar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: #fff;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  z-index: 99;

  &-spa {
    height: 60px;
  }

  .tabbar-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    height: 60px;
    font-size: 13px;
    color: #666;

    path {
      fill: #666 !important;
    }

    &-text {
      margin-top: 2px;
    }

    &.active,
    &:active {
      color: var(--wx-color);

      path {
        fill: var(--wx-color) !important;
      }
    }

    &:active {
      background-color: #f8f8f8;
    }

  }
}
</style>