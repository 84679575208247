
/**
 * 画模块化
 */

import { formatDate, timeAgo, dateAgo } from "./common.js";
import {
    getRandomUser,
    getRandomPictures,
    getRandomContent
} from "./fakedata.js";

class Draw {

    constructor(options) {
        this.canvasWidth = options.canvasWidth;
        this.system = 'android';
        this.dark = false;
        this.build = 0;
        this.scale = 1;
        // 随机动态缓存
        this.cache = {};
    }

    // 设置系统
    setSystem(system) {
        this.system = system;
    }

    // 设置深夜模式
    setDark(dark) {
        if (this.dark != dark) {
            this.cache = {};
        }
        this.dark = dark;
    }

    // 设置生成方式
    setBuild(mode) {
        // 清空缓存
        // this.cache = {};
        this.build = mode;
    }

    setScale(scale) {
        this.scale = scale;
    }

    // 画多条动态
    async drawDynamics(options = {}) {
        // 创建一个离线画布
        const { canvas, ctx } = this.createCanvas(this.canvasWidth, 1920 * 3);
        let top = 0;
        // 画顶部动态先
        const topCanvas = await this.drawRandmod('top');
        ctx.drawImage(topCanvas, 0, top);
        top += topCanvas.height + 40;
        this.drawLine(topCanvas, top);
        top += 40;
        // 画真实动态
        const dynamicCanvas = await this.drawDynamic(options);
        ctx.drawImage(dynamicCanvas, 0, top + 40);
        top += dynamicCanvas.height;
    }

    // 画分割线
    drawLine(ctx, top) {
        top += 40;
        ctx.strokeStyle = this.dark ? '#212121' : "#e5e5e5";
        ctx.beginPath();
        ctx.moveTo(0, top);
        ctx.lineTo(this.canvasWidth, top);
        ctx.lineWidth = 1;
        ctx.stroke();
        return 40 * 2 + 1;
    }

   

    /**
     * 随机画一条动态
     */
    async drawRandmod(key, date) {
        key = '_' + key;
        if (this.cache.hasOwnProperty(key)) {
            const canvas = this.cache[key];
            // 只更新时间
            await canvas.drawDate(date);
            return canvas;
        }
        ['user', 'pictures', 'content'].forEach(k => {
            if (!this.cache.hasOwnProperty(k)) {
                this.cache[k] = [];
            }
        });
        // 拿一个随机用户
        const user = getRandomUser(this.cache.user);
        this.cache.user = [...this.cache.user, user];
        // 拿一个随机图片
        const pictures = getRandomPictures(this.cache.pictures);
        this.cache.pictures = [...this.cache.pictures, pictures];
        // 拿到一条动态内容
        const content = getRandomContent(this.cache.content);
        this.cache.content = [...this.cache.content, content];
        const baseinfo = {
            isme: false,
            date,
            content,
            pictures,
            attr:0,
            ...user
        }
        // 画一个动态
        const dynamicCanvas = await this.drawDynamic({
            ...baseinfo,
            comments: [],
            likes: []
        });
        this.cache[key] = dynamicCanvas;
        return dynamicCanvas;
    }

    /**
     * 画status bar状态栏
     */
    async drawStatusBar(options = {}) {
        options = {
            time: '00:00',
            power: '80',
            network: '5G',
            charge: '80',
            wifi: true,
            ...options
        }
        let dark = this.dark;
        let fontColor = '#000';
        let bgColor = '#ededed';
        if (dark) {
            fontColor = '#d5d5d5';
            bgColor = '#111111';
        }
        let x = 0;
        // 44的边距必须要有
        let y = 44;
        // 图标高度
        const iconHeight = 36;
        // navbar 高度
        const navbarHeight = 130;
        // 封面尺寸 1080*774
        // 封面头像尺寸
        const avatar_width = 180;
        const height = this.build==2?(774+avatar_width/2.5):(y + iconHeight + navbarHeight);
        const { canvas, ctx } = this.createCanvas(this.canvasWidth, height);
        // 背景
        if(this.build==2){
            await this.createImage(ctx, options.cover, 0, 0, this.canvasWidth, 774);
            dark = true;
            fontColor = '#ffffff';
            // 画一个渐变黑色透明矩形
            // 设置填充样式为渐变
            ctx.fillStyle = 'rgba(0, 0, 0, .08)';
            // 绘制矩形
            ctx.fillRect(0, 0, this.canvasWidth, height - avatar_width/2.5);
            
            // 画头像
            let _x = this.canvasWidth - avatar_width - 40;
            let _y = height - avatar_width;
            await this.createImage(ctx,options.avatar,_x,_y,avatar_width,avatar_width,20);
            // 画昵称
            ctx.font = 'bold 56px sans-serif';
            ctx.fillStyle = '#fff';
            const metrics = ctx.measureText(options.nickname);
            _x -= metrics.width + 25;
            _y += 30;
            ctx.fillText(options.nickname, _x, _y);
        }else{
            ctx.fillStyle = bgColor;
            ctx.fillRect(0, 0, this.canvasWidth, height);
        }
        const tmpY = y;
        y -= 100;
        for (let index = 0; index < 2; index++) {
            let pos = null;
            if (this.system == "android") {
                // 铃声
                pos = await this.createImagePhone(
                    ctx,
                    dark ? "ring_dark.png" : "ring.png",
                    x,
                    y,
                    "auto",
                    iconHeight
                );
                x += pos.width + 20;
                // 蓝牙
                pos = await this.createImagePhone(
                    ctx,
                    dark ? "bluetooth_dark.png" : "bluetooth.png",
                    x,
                    y,
                    "auto",
                    iconHeight
                );
                x += pos.width + 20;
                // 5G标识
                ctx.fillStyle = fontColor;
                ctx.font = "bold 14px sans-serif";
                ctx.fillText(options.network, x - 4, y);
            }
            // 网络
            pos = await this.createImagePhone(
                ctx,
                dark ? "network_dark.png" : "network.png",
                x,
                y,
                "auto",
                iconHeight
            );
            x += pos.width + 20;
            if (options.wifi) {
                // wifi
                pos = await this.createImagePhone(
                    ctx,
                    dark ? "wifi_dark.png" : "wifi.png",
                    x,
                    y,
                    "auto",
                    iconHeight
                );
                x += pos.width + 20;
            } else if (this.system == "ios") {
                // 5G标识
                ctx.fillStyle = fontColor;
                ctx.font = "bold 34px sans-serif";
                const metrics = ctx.measureText(options.network);
                ctx.fillText(options.network, x, y + 5);
                x += metrics.width + 20;
            }
            // 电池
            const power_val =
                parseInt(options.power) > 100 ? 100 : parseInt(options.power);
            if (this.system == "android") {
                // 画电池电量文字
                ctx.fillStyle = fontColor;
                ctx.font = "30px sans-serif";
                const ret = this.drawText(ctx, x, y, power_val + "%", 'auto', 30, 'left', 'center');
                x += ret.width + 4;
            }
            // 画电池图标
            pos = await this.createImagePhone(
                ctx,
                dark ? "power_dark.png" : "power.png",
                x,
                y,
                "auto",
                iconHeight
            );
            // 画电池电量
            ctx.fillStyle =
                power_val <= 20 ? "#e70000" : options.charge ? "#00e731" : fontColor;
            this.drawRoundedRect(
                ctx,
                x + 6,
                y + 6,
                (pos.width - (this.system == "ios" ? 19 : 15)) * (power_val / 100),
                24,
                this.system == "ios" ? 6 : 2
            );
            if (options.charge) {
                if (this.system == "android") {
                    x += pos.width;
                    // 画充电状态
                    pos = await this.createImagePhone(
                        ctx,
                        dark ? "charge_dark.png" : "charge.png",
                        x + 10,
                        y,
                        "auto",
                        iconHeight
                    );
                } else {
                    // 画充电状态
                    await this.createImagePhone(
                        ctx,
                        dark ? "charge_dark.png" : "charge.png",
                        x + 15,
                        y,
                        "auto",
                        iconHeight
                    );
                }
            }
            x += pos.width + 60;
            if (index == 0) {
                // 清除区域
                ctx.fillStyle = bgColor;
                ctx.fillRect(0, y, x, iconHeight);
                x = this.canvasWidth - x;
                y = tmpY;
            }
        }
        // 时间
        ctx.fillStyle = fontColor;
        ctx.font = "bold 36px sans-serif";
        this.drawText(ctx, 60, y, formatDate(options.time, "HH:mm"), 'auto', iconHeight, 'left', 'center');
        y += 36 + 10;
        // ****** 画navbar ******
        if(this.build != 2){
            // 写文字
            ctx.font = "45px sans-serif";
            this.drawText(ctx, 0, y, this.build == 0 ? '详情' : '朋友圈', this.canvasWidth, navbarHeight, 'center', 'center');
            // 更多
            await this.createImageIcon(
                ctx,
                dark ? "more_dark.png" : "more.png",
                this.canvasWidth - 42 - 50,
                y + (navbarHeight - 42) / 2,
                42,
                42
            );
        }else{
            // 相机
            await this.createImageIcon(
                ctx,
                "photo.png",
                this.canvasWidth - 42 - 50,
                y + (navbarHeight - 42) / 2 - 4,
                54,
                56
            );
        }
        
        // 返回
        await this.createImageIcon(
            ctx,
            dark ? "back_dark.png" : "back.png",
            40,
            y + (navbarHeight - 42) / 2,
            42,
            42
        );
        return canvas;
    }

    /**
     * 画底部评论框
     */
    async drawInputBar() {
        let bgColor = '#f8f8f8';
        if (this.dark) {
            bgColor = '#2c2c2c';
        }
        const height = 135;
        let x = 0, y = 0;
        const { canvas, ctx } = this.createCanvas(this.canvasWidth, height);
        // 画背景
        ctx.fillStyle = bgColor;
        ctx.fillRect(x, y, this.canvasWidth, height);
        x = 30;
        y = 20;
        // 画一个圆角矩形
        const rectWidth = this.canvasWidth - x - 300;
        const rectHeight = height - 20 * 2;
        ctx.fillStyle = this.dark ? '#191919' : '#ffffff';
        this.drawRoundedRect(ctx, x, y, rectWidth, rectHeight, 16);
        // 写文字
        ctx.fillStyle = '#999';
        ctx.font = "40px sans-serif";
        this.drawText(ctx, x + 20, y, '评论', 'auto', rectHeight, 'left', 'center');
        x += rectWidth;
        x += 30;
        // 画表情符号
        await this.createImageIcon(
            ctx,
            this.dark ? "face_dark.png" : "face.png",
            x,
            (height - 70) / 2,
            70,
            70
        );
        x += 30 + 70;
        // 画发送按钮
        ctx.fillStyle = '#999';
        y += 10;
        const btnWidth = 140;
        const btnHeight = height - y * 2;
        this.drawRoundedRect(ctx, x, y, btnWidth, btnHeight, 5);
        ctx.fillStyle = bgColor;
        this.drawRoundedRect(ctx, x + 1, y + 1, btnWidth - 2, btnHeight - 2, 5);
        // 写文字
        ctx.fillStyle = '#999';
        ctx.font = "36px sans-serif";
        this.drawText(ctx, x, y, '发送', btnWidth, btnHeight, 'center', 'center');
        return canvas;
    }

     // 画封面
     async drawCover(options = {}){
        const height = 300;
        // 创建一个画布
        const { canvas, ctx } = this.createCanvas(this.canvasWidth, height);
        // 创建图片
        await this.createImage(ctx, options.cover, 0, 0, this.canvasWidth, height);
        return canvas;
     }

    /**
     * 画一条动态
     */
    async drawDynamic(options = {}) {
        options = {
            avatar: './icons/default-avatar.jpg',
            nickname: '没有昵称',
            content: '没有内容',
            pictures: [],
            date: '',
            isme: true,
            likes: [],
            comments: [],
            ...options
        }
        if (!options.date || isNaN(options.date)) {
            options.date = new Date();
        }
        // 基础配置
        let fontColor = '#000';
        let genColor = '#444';
        let genBg = '#f7f7f7';
        if (this.dark) {
            fontColor = '#d5d5d5';
            genColor = '#7e7e7e';
            genBg = '#252525';
        }
        // 坐标
        let x = 0;
        let y = 0;
        // 边距
        const padding = 40;
        // 真实的绘画宽度
        const width = this.canvasWidth - padding * 2;
        // 预设画布高度为2个屏幕高度，因为我也不知道用户会画多少东西
        let height = 1920 * 6;
        // 从边距开始画
        x += padding;
        // 创建一个画布
        const { canvas, ctx } = this.createCanvas(this.canvasWidth, height);
        // 画头像
        await this.createImage(ctx, options.avatar, x, y, 120, 120, 14);
        // 画昵称
        // 此处更新坐标，昵称内容图片坐标起点
        x += 145;
        // 加点边距
        y += 16;
        ctx.fillStyle = "#5a6d97";
        ctx.font = "bold 41px sans-serif";
        ctx.fillText(options.nickname, x, y);
        // 坐标加文字高度
        y += 40;
        // 内容和图片的盒子宽度 总宽度减去当前的x再减一个边距
        const contentWidth = this.canvasWidth - x - padding;
        // 画内容
        if (options.content) {
            ctx.fillStyle = fontColor;
            ctx.font = "42px sans-serif";
            // 更新坐标加边距
            y += 34;
            // 有内容的时候画出来
            const lineHeight = this.drawWrappedText(
                ctx,
                options.content,
                x,
                y,
                contentWidth,
                54
            );
            // 更新y的坐标
            y += lineHeight - 10;
        }
        // 画链接
		if (options.attr == 1) {
			// 更新坐标加边距
			y += 30;
			ctx.fillStyle = "#00000000";
			ctx.font = "38px Arial, sans-serif";
			const fontHeight = await this.drawWrappedText(ctx, options.link.title, x + 170, y, contentWidth - 180,
				50)
			const fontTop = y + ((150 - fontHeight + 12) / 2)
			// 画背景矩形
			ctx.fillStyle = genBg;
			this.drawRoundedRect(
				ctx,
				x,
				y,
				contentWidth,
				150
			);
			// 画icon
			await this.createImage(ctx, options.link.cover, x + 14, y + 14, 124, 124);
			ctx.fillStyle = fontColor;
			await this.drawWrappedText(ctx, options.link.title, x + 170, fontTop, contentWidth - 180,
				50)
			// 更新y的坐标
			y += 170;
		}
        // 画图片
        if (options.attr == 0 && options.pictures.length) {
            if (options.pictures.length == 1) {
                // 更新坐标加边距
                y += 34;
                // 一张图片的宽度是总的一半
                const res = await this.createImage(
                    ctx,
                    options.pictures[0],
                    x,
                    y,
                    contentWidth / 2,
                    "auto"
                );
                // 更新坐标加图片高度
                y += res.height;
            } else {
                // 更新坐标加边距
                y += 34;
                // 一张以上图片的宽度则为容器宽度的1/3 + 边距
                let pictureWidth = contentWidth / 3 - 15;
                // 4张图片
                let cutup = 3;
                if (options.pictures.length == 4) {
                    cutup = 2;
                }
                let _x = x;
                let _y = y;
                for (let i = 0; i < options.pictures.length; i++) {
                    await this.drawImageCover(ctx, options.pictures[i], _x, _y, pictureWidth);
                    _x += pictureWidth + 5;
                    if ((i + 1) % cutup == 0) {
                        _y += pictureWidth + 5;
                        _x = x;
                    }
                }
                // 更新y的坐标 图片宽度乘以行数
                y += (pictureWidth + 5) * Math.ceil(options.pictures.length / cutup);
            }
        }
        // 更新坐标加边距
        y += 40;
        // 记录此时的x,y  TODO 没得办法了，只能通过这种方法做到局部更新了
        canvas.date = {
            x,
            y,
            w: 0
        };
        canvas.drawDate = async (date) => {
            // 画时间
            ctx.fillStyle = genColor;
            ctx.font = "36px sans-serif";
            if (this.build == 1) {
                // 如果是圈内的话，时间要个性显示
                date = timeAgo(date);
            } else {
                date = dateAgo(date);
            }
            // date width
            const metrics = ctx.measureText(date);
            const width = metrics.width + 58;
            // 清除区域
            ctx.clearRect(canvas.date.x, canvas.date.y - 10, canvas.date.w, 38 + 10);
            ctx.textBaseline = "top";
            ctx.fillText(date, canvas.date.x, canvas.date.y);
            if (options.isme) {
                // 画删除按钮
                await this.createImageIcon(
                    ctx,
                    "delete.png",
                    canvas.date.x + metrics.width + 20,
                    canvas.date.y - 3,
                    34,
                    38
                );
            }
            // 记录宽度
            canvas.date.w = width;
        }
        await canvas.drawDate(options.date);
        // 画操作按钮
        y -= 6;
        // 移动x到操作按钮位置，使用画布宽度减去边距减去操作按钮宽度
        x = this.canvasWidth - padding - 88;
        ctx.fillStyle = this.dark ? "#2c2c2c" : "#f8f8f8";
        this.drawRoundedRect(ctx, x, y - 4, 88, 55, 8);
        // 画按钮中的圆点
        for (let i = 0; i < 2; i++) {
            ctx.fillStyle = "#5a6d97";
            ctx.beginPath();
            ctx.arc(x + 28 + i * 30, y + 24, 6, 0, Math.PI * 2);
            ctx.closePath();
            ctx.fill();
            // 设置描边颜色和宽度
            ctx.strokeStyle = "#5a6d97";
            ctx.lineWidth = 1;
            // 描边圆
            ctx.stroke();
        }
        // y坐标加上按钮高度
        y += 80;
        // 更新画布高度
        height = y;

        const avatar_width = [100,94,83,74][options.likenum];
        
        // ******* 画点赞 ********
        if (options.likes.length) {
            // x 归位
            x = padding;
            // 更新y坐标加边距
            y += 20;
            // icon 宽度18 + 左右边距10
            const icon_width = 45 + 34;
            // 真实的点赞头像容器宽度 画布宽度减去两个边距
            const avatar_warp_width = width - icon_width;
            if (this.build == 0) {
                // 头像宽度
                // const avatar_width = 74;
                // 一个头像元素宽度
                const item_width = avatar_width + 17;
                // 一行显示多少个头像
                const avatar_count = Math.floor(avatar_warp_width / item_width);
                // 头像行
                const avatar_line = Math.ceil(options.likes.length / avatar_count);
                // 画圆角背景矩形
                ctx.fillStyle = genBg;
                this.drawRoundedRect(
                    ctx,
                    x,
                    y,
                    width,
                    // 评论行数 * 评论头像高度 + 上下边距 - 头像下边距
                    item_width * avatar_line + 30 + 30 - 17,
                    10
                );
                // 更新y边距
                y += 30;
                // 画icon
                await this.createImageIcon(ctx, "like.png", x + 14, y + 10, 50, 50);
                // 更新此时的x
                x += icon_width;
                // 画头像
                let _x = x;
                for (let index = 0; index < options.likes.length; index++) {
                    const item = options.likes[index];
                    _x = _x + item_width;
                    if (index % avatar_count == 0) {
                        _x = x;
                        y += item_width;
                    }
                    await this.createImage(
                        ctx,
                        item.avatar,
                        _x,
                        y - item_width,
                        avatar_width,
                        avatar_width,
                        7
                    );
                }
            } else {
                ctx.font = "bold 32px sans-serif";
                const nicknames = options.likes.map(i => i.nickname).join(', ');
                // 只为了取到文字高度
                const lineHeight = this.drawWrappedText(ctx, nicknames, x, y, avatar_warp_width, 50)
                // 画圆角背景矩形
                ctx.fillStyle = genBg;
                this.drawRoundedRect(
                    ctx,
                    x,
                    y - 5,
                    width,
                    // 上下20的边距 -5是文字行高
                    lineHeight + 20 * 2 - 5,
                    10
                );
                // 画icon
                await this.createImageIcon(ctx, "like.png", x + 16, y + 10, 50, 50);
                ctx.fillStyle = "#5a6d97";
                // 圈内只显示昵称 为什么不是y + 20而是y + 25呢，我发现文字会自己冒出一点头来，不知道为什么...
                this.drawWrappedText(ctx, nicknames, x + icon_width, y + 25, avatar_warp_width - 20, 50)
                y += lineHeight + 18;
            }
            // 更新画布高度
            height = y;
        }
        // ******* 画评论 *******
        if (options.comments.length) {
            // x归位
            x = padding;
            // 此处的15是和上面的点赞器的一个分割边距
            y += 15;
            let _tmpX = x;
            let _tmpY = y + 26;
            // icon 宽度45 + 左右边距17
            const icon_width = 45 + 34;
            _tmpX += icon_width;
            // 评论的容器宽度
            const comment_warp_width = width - icon_width;
            let _x = _tmpX;
            let _y = _tmpY;
            
            const fontSize = 40 - options.likenum;
            
            // 画两遍，第一遍为了知道高度
            for (let index = 0; index < 2; index++) {
                _y = _tmpY;
                for (let j = 0; j < options.comments.length; j++) {
                    const item = options.comments[j];
                    if (this.build == 0) {
                        _x = _tmpX;
                        // 画头像
                        // const avatar_width = 74;
                        await this.createImage(ctx, item.avatar, _x, _y, avatar_width, avatar_width, 7);
                        _x += avatar_width + 20;
                        // 真实的内容宽度
                        const content_width = comment_warp_width - avatar_width - 40;
                        // 画昵称
                        ctx.fillStyle = "#5a6d97";
                        ctx.font = (fontSize-2)+"px sans-serif bold";
                        ctx.fillText(item.nickname, _x, _y);
                        // 画日期
                        ctx.fillStyle = "#777";
                        ctx.font = [32,31,30,28][options.likenum]+"px sans-serif";
                        const date = dateAgo(item.date, 'yyyy年MM月dd日 HH:mm');
                        const metrics = ctx.measureText(date);
                        ctx.fillText(date, width - metrics.width, _y);
                        // 字体高度
                        _y += 34;
                        // 上边距
                        _y += [30,28,24,20][options.likenum];
                        // 如果是回复
                        let offset = 0;
                        if (item?.quote) {
                            ctx.fillStyle = fontColor;
                            ctx.font = fontSize+"px sans-serif";
                            const metrics = ctx.measureText("回复");
                            let _qx = _x;
                            ctx.fillText("回复", _qx, _y);
                            ctx.fillStyle = "#5a6d97";
                            ctx.font = (fontSize+1)+"px sans-serif bold";
                            _qx += metrics.width + 4;
                            ctx.fillText(item.quote.nickname, _qx, _y);
                            const metrics2 = ctx.measureText(item.quote.nickname);
                            offset = metrics2.width + 4 + metrics.width + 4;
                        }
                        // 画评论内容
                        ctx.fillStyle = fontColor;
                        ctx.font = (fontSize+1)+"px sans-serif";
                        const lineHeight = this.drawWrappedText(
                            ctx,
                            item.content,
                            _x,
                            _y,
                            content_width,
                            54,
                            offset
                        );
                        _y += lineHeight + 30;
                    } else {
                        // 来吧，这代码我也没法写了，感觉已经屎山了
                        // 如果是圈内的话，它不显示头像和日期...
                        // 画昵称
                        ctx.fillStyle = "#5a6d97";
                        ctx.font = "34px sans-serif";
                        ctx.fillText(item.nickname, _x, _y);
                        // 取到昵称宽度
                        const metrics = ctx.measureText(item.nickname);
                        // 画内容
                        ctx.fillStyle = fontColor;
                        const lineHeight = this.drawWrappedText(ctx, ': ' + item.content,
                            _x, _y, comment_warp_width - 20, 54, metrics.width + 4);
                        _y += lineHeight + 10;
                    }
                }
                // 去掉最后一个边距，此时的_y则是整个动态的高度
                _y -= 30;
                // 加下边距
                _y += 16;
                if (index == 0) {
                    // 画圆角背景矩形
                    ctx.fillStyle = genBg;
                    _y -= y;
                    // 减去y（y所有上面的元素的高度），是评论区内容的高度（不包含上下边距）
                    this.drawRoundedRect(ctx, x, y, width, _y);
                    // 画icon
                    await this.createImageIcon(ctx, "comment.png", x + 14, y + (this.build == 0 ? 38 : 10), 50, 50);
                    // 更新y
                    y += _y;
                }
            }

            // 更新画布高度
            height = y;
        }

        // 在修改高度之前保存当前的画布内容
        const imageData = ctx.getImageData(0, 0, this.canvasWidth, height);
        // 修改 canvas 高度
        canvas.height = height;
        // 重新绘制保存的内容到新的画布
        ctx.putImageData(imageData, 0, 0);
        return canvas;
    }

    // 创建一个离屏渲染画布
    createCanvas(width, height) {
        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.textBaseline = "top";
        return { canvas, ctx };
    }

    // 创建一个图片
    createImage(ctx, src, x, y, width, height, radius = null) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.crossOrigin = 'anonymous';
            img.onload = () => {
                if (radius) {
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x + radius, y);
                    ctx.lineTo(x + width - radius, y);
                    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
                    ctx.lineTo(x + width, y + height - radius);
                    ctx.quadraticCurveTo(
                        x + width,
                        y + height,
                        x + width - radius,
                        y + height
                    );
                    ctx.lineTo(x + radius, y + height);
                    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
                    ctx.lineTo(x, y + radius);
                    ctx.quadraticCurveTo(x, y, x + radius, y);
                    ctx.closePath();
                    ctx.clip();
                }
                // Draw the image
                if (width == "auto") {
                    width = img.width * (height / img.height);
                }
                if (height == "auto") {
                    height = img.height * (width / img.width);
                }
                ctx.drawImage(img, x, y, width, height);
                if (radius) {
                    ctx.restore();
                }
                resolve({
                    width,
                    height,
                });
            };
        });
    }
    // 创建一个图片指向Icon目录
    createImageIcon(ctx, src, x, y, width, height, radius) {
        src = "./icons/" + src;
        return this.createImage(ctx, src, x, y, width, height, radius);
    }
    // 创建一个图片指向手机型号
    createImagePhone(ctx, src, x, y, width, height, radius) {
        src = "./" + this.system + "/" + src;
        return this.createImage(ctx, src, x, y, width, height, radius);
    }

    // 找到字符串中的链接
    findLinks(text) {
        // 正则表达式确保域名部分只包含英文字符、数字、连字符和点，忽略中文等非英文字符
        const linkPattern = /https?:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}\/*[^\s\u4e00-\u9fa5]*\s*/g;
        let match;
        let positions = [];

        while ((match = linkPattern.exec(text)) !== null) {
            positions.push({
                url: match[0],          // 匹配到的链接
                start: match.index,     // 链接起始位置
                end: match.index + match[0].length  // 链接结束位置
            });
        }

        return positions;
    }

    // 换行文本
    drawWrappedText(ctx, text, x, y, maxWidth, lineHeight, offset = 0) {
        // 保存当前文字颜色
        const tmpColor = ctx.fillStyle;
        // 找到链接位置
        const links = this.findLinks(text);

        const words = [...text];
        let linenum = 0;
        const left = x;
        let _x = x + offset;
        for (let i = 0; i < words.length; i++) {
            // 是否是链接
            const isLink = links.some(link => link.start <= i && i < link.end);

            if (isLink) {
                // 链接颜色
                ctx.fillStyle = "#5a6d97";
            } else {
                // 文字颜色
                ctx.fillStyle = tmpColor;
            }

            const testLine = words[i];
            const metrics = ctx.measureText(testLine);
            const testWidth = metrics.width;
            // 剩余空间
            const remainingWidth = maxWidth - (_x - left);
            if (remainingWidth < testWidth) {
                y += lineHeight;
                linenum++;
                _x = left;
            }
            ctx.fillText(testLine, _x, y);
            _x += testWidth;
        }
        linenum++;
        return linenum * lineHeight;
    }
    // 以cover模式绘制正方形容器图片
    drawImageCover(ctx, src, squareX, squareY, squareSize) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.crossOrigin = 'anonymous';
            img.onload = () => {
                const imageWidth = img.width;
                const imageHeight = img.height;
                const imageAspectRatio = imageWidth / imageHeight;
                const squareAspectRatio = 1; // 正方形的宽高比为 1

                let renderWidth, renderHeight, offsetX, offsetY;

                // 根据宽高比调整渲染大小
                if (imageAspectRatio > squareAspectRatio) {
                    // 图片更宽，缩放高度填充正方形，裁剪宽度
                    renderHeight = squareSize;
                    renderWidth = renderHeight * imageAspectRatio;
                    offsetX = (squareSize - renderWidth) / 2;
                    offsetY = 0;
                } else {
                    // 图片更高，缩放宽度填充正方形，裁剪高度
                    renderWidth = squareSize;
                    renderHeight = renderWidth / imageAspectRatio;
                    offsetX = 0;
                    offsetY = (squareSize - renderHeight) / 2;
                }

                // 保存上下文并设置裁剪区域
                ctx.save();
                ctx.beginPath();
                ctx.rect(squareX, squareY, squareSize, squareSize);
                ctx.clip();

                // 将图片绘制到正方形区域内
                ctx.drawImage(
                    img,
                    squareX + offsetX,
                    squareY + offsetY,
                    renderWidth,
                    renderHeight
                );

                // 恢复上下文到未裁剪状态
                ctx.restore();

                resolve({
                    width: imageWidth,
                    height: imageHeight,
                });
            };
        });
    }
    // 画一个圆角矩形
    drawRoundedRect(ctx, x, y, width, height, radius) {
        if (typeof radius === "number") {
            radius = { tl: radius, tr: radius, br: radius, bl: radius };
        } else {
            radius = Object.assign({ tl: 0, tr: 0, br: 0, bl: 0 }, radius);
        }

        ctx.beginPath();
        ctx.moveTo(x + radius.tl, y);
        ctx.lineTo(x + width - radius.tr, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
        ctx.lineTo(x + width, y + height - radius.br);
        ctx.quadraticCurveTo(
            x + width,
            y + height,
            x + width - radius.br,
            y + height
        );
        ctx.lineTo(x + radius.bl, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
        ctx.lineTo(x, y + radius.tl);
        ctx.quadraticCurveTo(x, y, x + radius.tl, y);
        ctx.closePath();
        ctx.fill();
    }
    /**
     * 画一个文字可设置对齐
     * @param {*} ctx 
     * @param {*} text 文本
     * @param {*} warpWidth 容器宽度
     * @param {*} warpHeigh 容器高度
     * @param {*} horizontal center left right 水平
     * @param {*} vertical center top bottom 垂直
     */
    drawText(ctx, x, y, text, warpWidth, warpHeigh, horizontal = 'left', vertical = 'top') {
        const metrics = ctx.measureText(text);
        // 拿到文字宽度
        const textWidth = metrics.width;
        // 拿到文字高度
        const textHeight = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
        if (warpWidth === 'auto') {
            warpWidth = textWidth;
        }

        if (horizontal == 'center') {
            x += warpWidth / 2 - textWidth / 2;
        } else if (horizontal == 'right') {
            x += warpWidth - textWidth;
        }

        if (vertical == 'center') {
            y += (warpHeigh - textHeight) / 2;
        } else if (vertical == 'bottom') {
            y += warpHeigh - textHeight;
        }
        ctx.fillText(text, x, y);

        return { width: warpWidth, height: textHeight };
    }
}


export default Draw;