<template>
  <div id="app">
    <Home></Home>
  </div>
</template>

<script>

window.msg = function (text, duration = 2000) {
  if (window.msgTimer) {
    clearTimeout(msgTimer);
    window.msgTimer = null;
    document.querySelector(".msg").remove();
  }
  window.msgTimer = setTimeout(() => {
    document.body.removeChild(el);
    window.msgTimer = null;
  }, duration);
  const el = document.createElement("div");
  el.innerText = text;
  el.className = "msg";
  document.body.appendChild(el);
};

import Home from "./components/Home.vue";

export default {
  name: "App",
  components: {
    Home,
  },
};
</script>

<style lang="scss">
:root {
  --wx-color: #06C05F;
}
body {
  padding: 0;
  margin: 0;
}

#app{
  position: relative;
}

#app::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('~/public/images/bg.jpg');
  background-size: 100% 100%;
  background-position: center;
  filter: blur(60px); /* 调整模糊程度 */
  z-index: -1; /* 使背景图片在内容后面 */
}

input,
select,
textarea {
  padding: 0;
  margin: 0;
  border-width: 0;
  background-color: #eeeeee;
  outline: none;
  height: 42px;
  line-height: 42px;
  padding: 0 10px;
  line-height: 1;
  border-radius: 5px;
  box-sizing: border-box;
  width: 100%;
}

textarea {
  padding: 10px;
  min-height: 100px;
  resize: none;
}

.radio-group {
  display: flex;
  gap: 10px;
  &-item {
    font-size: 13px;
    flex: 1;
    background-color: #eeeeee;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border: solid 1px var(--wx-color);
      border-radius: 10px;
      vertical-align: middle;
      margin-right: 5px;
    }
    &.active {
      background-color: var(--wx-color);
      color: #fff;
      &::before {
        background-color: #fff;
      }
    }
  }
}

// 消息
.msg {
  position: fixed;
  z-index: 10001;
  left: 50%;
  top: 50%;
  max-width: 60%;
  transform: translate(-50%, -50%);
  background-color: rgba($color: #000000, $alpha: 0.8);
  color: #fff;
  padding: 10px;
  font-size: 13px;
  border-radius: 6px;
}
</style>
