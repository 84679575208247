<template>
  <div style="position: relative">
    <div class="loading" v-if="loading">
      <svg
        t="1724213794930"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="4290"
        width="16"
        height="16"
      >
        <path
          d="M272 704a47.84 47.84 0 0 0-33.936 14.064l-96 96a48 48 0 1 0 67.872 67.872l96-96A48 48 0 0 0 272 704z m-48-192a48 48 0 0 0-48-48H48a48 48 0 1 0 0 96h128a48 48 0 0 0 48-48z m-14.064-369.936a48 48 0 1 0-67.872 67.872l96 96a48 48 0 1 0 67.872-67.872l-96-96zM752 320a47.84 47.84 0 0 0 33.936-14.064l96-96a48 48 0 1 0-67.872-67.872l-96 96A48 48 0 0 0 752 320z m33.936 398.064a48 48 0 1 0-67.872 67.872l96 96a48 48 0 1 0 67.872-67.872l-96-96zM512 800a48 48 0 0 0-48 48v128a48 48 0 1 0 96 0v-128a48 48 0 0 0-48-48z m464-336h-128a48 48 0 1 0 0 96h128a48 48 0 1 0 0-96zM512 0a48 48 0 0 0-48 48v128a48 48 0 1 0 96 0V48a48 48 0 0 0-48-48z"
          fill="#ffffff"
          p-id="4291"
        ></path>
      </svg>
      正在生成...
    </div>
    <canvas id="canvas" ref="canvas"></canvas>
    <img :src="base64" v-if="iswx" />
  </div>
</template>

<script>
import { random } from "../assets/js/common.js";
import Draw from "./../assets/js/draw.js";
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      base64: "",
      iswx: false,
      loading: false,
    };
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        this.refresh();
      },
      deep: true,
    },
  },
  async mounted() {
    this.canvas = this.$refs.canvas;
    this.ctx = this.canvas.getContext("2d");

    this.width = 1080;
    this.height = 2340;

    this.defaultHeight = this.height;

    this.canvas.width = this.width;
    this.canvas.height = this.height;

    this.draw = new Draw({
      canvasWidth: this.width,
    });

    // this.canvas.style.width = 360 + "px";
    // this.canvas.style.height = 780 + "px";

    this.$nextTick(() => {
      this.iswx = this.isWechat();
      this.refresh();
    });
  },
  methods: {
    getCanvas() {
      return this.canvas;
    },
    // 刷新
    async refresh(updateHeight = false) {
      this.loading = true;
      const param = this.data.param;
      const baseinfo = this.data.baseinfo;
      const comments = this.data.comments;
      const likes = this.data.likes;
      // 清空画布
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.ctx.fillStyle = param.dark ? "#191919" : "#fff";
      this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
      this.draw.setDark(param.dark);
      this.draw.setSystem(param.system);
      this.draw.setBuild(param.build);
      // 重绘高度
      let height = 0;
      // 画状态栏
      const statusBarCanvas = await this.draw.drawStatusBar({
        ...param,
        cover: baseinfo.cover,
        avatar: baseinfo.avatar,
        nickname: baseinfo.nickname,
        isme: true,
      });
      this.ctx.drawImage(statusBarCanvas, 0, 0);
      // height += statusBarCanvas.height;
      let dynamicCanvas = null;
      if (param.build == 0) {
        dynamicCanvas = await this.renderDynamic(
          statusBarCanvas.height + 40,
          param,
          baseinfo,
          comments,
          likes
        );
        height += 40;
      } else {
        let _height = statusBarCanvas.height;
        if(param.build == 2){
          _height+= 70;
        }
        dynamicCanvas = await this.renderDynamics(
          _height,
          param,
          baseinfo,
          comments,
          likes,
          param.build != 2
        );
      }
      height += dynamicCanvas.height;
      if (param.build == 0) {
        // 画底部
        const inputBarCanvas = await this.draw.drawInputBar();
        this.ctx.drawImage(
          inputBarCanvas,
          0,
          this.canvas.height - inputBarCanvas.height
        );
        height += inputBarCanvas.height + 20;
      }
      // 截长图
      if (param.screenshot == 1 && !updateHeight) {
        this.canvas.height =
          height > this.defaultHeight ? height : this.defaultHeight;
        this.refresh(true);
        return;
      }
      // 恢复第一屏canvas高度
      if (param.screenshot == 0 && this.canvas.height > this.defaultHeight) {
        this.canvas.height = this.defaultHeight;
        this.refresh(true);
        return;
      }

      if (this.iswx) {
        this.base64 = this.canvas.toDataURL();
      }
      this.loading = false;
    },
    // 单条渲染
    async renderDynamic(top, param, baseinfo, comments, likes) {
      // 画一个动态
      const dynamicCanvas = await this.draw.drawDynamic({
        ...param,
        ...baseinfo,
        comments,
        likes,
      });
      this.ctx.drawImage(dynamicCanvas, 0, top);
      return dynamicCanvas;
    },
    // 多条渲染
    async renderDynamics(top, param, baseinfo, comments, likes,istop=true) {
      let baseinfoDate = baseinfo.date || new Date();
        let _top = 0;
      if(istop){
        const topCanvas = await this.draw.drawRandmod(
          "top",
          new Date(baseinfoDate + random(10000, 900 * 1000))
        );
        // 裁剪高度
        const clip_y = topCanvas.height * (random(4, 10) / 10);
        const clip_h = topCanvas.height - clip_y;
        this.ctx.drawImage(
          topCanvas,
          0,
          clip_y,
          this.width,
          clip_h,
          0,
          top,
          this.width,
          clip_h
        );
        _top = top;
        top += clip_h;
        // 分割线
        top += this.draw.drawLine(this.ctx, top);
      }
      // 画本条动态
      const dynamicCanvas = await this.renderDynamic(
        top,
        param,
        baseinfo,
        comments,
        likes
      );
      top += dynamicCanvas.height;
      // 检查当前高度是否超过屏幕高度
      if (top > this.height) {
      } else {
        let i = 0;
        let date = 0;
        while (true) {
          if (top > this.height) {
            break;
          }
          const min = 1000 * 30;
          const max = min * 180;
          date += random(min, max);
          // 分割线
          top += this.draw.drawLine(this.ctx, top);
          // 没有超过，再创建一个随机动态
          const randCanvas = await this.draw.drawRandmod(
            "t" + i++,
            new Date(baseinfoDate - date)
          );
          this.ctx.drawImage(randCanvas, 0, top);
          top += randCanvas.height;
        }
      }
      return {
        height: top - _top,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
canvas,
img {
  width: 360px;
}

img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  background-color: rgba($color: #000000, $alpha: 0.8);
  height: 40px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 13px;
  padding: 0 15px;
  border-radius: 40px;
  z-index: 10;

  svg {
    margin-right: 5px;
    animation: rotate 1s linear infinite;
  }
}

@media screen and (max-width: 768px) {
  canvas,
  img {
    width: 100%;
  }
}
</style>